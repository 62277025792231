import React, { useEffect, useRef, useState } from "react";
import TabelProduksi from "../../components/Table/TabelProduksi";
import LoaderTable from "../../molecules/LoaderTable";
import TextInput from "../../molecules/TextInput";
import DateInput from "../../molecules/DateInput";
import { SiMicrosoftexcel } from "react-icons/si";
import { FaCheck } from "react-icons/fa";
import moment from "moment";
import { useDownloadExcel } from 'react-export-table-to-excel';

const ProduksiPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [allData, setAllData] = useState([]);
  const [dataTabel, setDataTabel] = useState([])
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [query, setQuery] = useState("");
  const tableRef = useRef(null);

  const apiUrl = process.env.REACT_APP_API_URL_PRODUKSI + moment(date).format('YYYYMMDD')

  function getFromChildText(data) {
    setQuery(data)
  }
  function getFromChildDate(data) {
    setDate(data)
    // console.log("date", date)
  }

  useEffect(() => {
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setAllData(data.data);
        const namaDataKecil = Object.keys(data.data);
        const namaUpper = Object.keys(data.data).map((arrayName) => {
          const kapitalDanSpasi = arrayName.replace(/([A-Z])/g, " $1").trim();
          return kapitalDanSpasi.toUpperCase();
        });
        const newDataTabel = [];

        for (let i = 0; i < namaDataKecil.length; i++) {
          const totalPcs = data.data[namaDataKecil[i]].reduce(
            (total, item) => total + item.pcs,
            0
          );
          const totalQty = data.data[namaDataKecil[i]].reduce(
            (total, item) => total + item.qty,
            0
          );
          const totalRendemen = data.data[namaDataKecil[i]].reduce(
            (total, item) => total + item.rendemen,
            0
          );

          const totalData = {
            nama_data: namaDataKecil[i],
            nama_upper: namaUpper[i],
            total_pcs: totalPcs,
            total_qty: totalQty,
            total_rendemen: totalRendemen,
          };

          newDataTabel.push(totalData);
        }
        setIsLoading(false);
        setDataTabel(newDataTabel);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `Raw Materials`,
    sheet: 'Raw Materials'
  })
  return (
    <>
      <div className="mt-[10px] w-full max-w-[98%] mx-auto h-fit font-light">
        <div className="basis-[70%] border bg-white shadow-md rounded-[4px]">
          <div className="container-fluid p-2">
            <div className="table-responsive">
              <div className="flex">
                <div className="ml-auto flex items-center pb-1">
                  <div className="flex items-center rounded-[5px] mr-1">
                    <TextInput getFromChild={getFromChildText} isLoading={isLoading} />
                  </div>
                  <div className="flex items-center rounded-l-md border hover:border-y-[#adadaf] hover:border-l-[#adadaf]">
                    <DateInput getFromChild={getFromChildDate} isLoading={isLoading} tgl={date} />
                  </div>
                  <div className={`px-2 h-[33px] rounded-r-md bg-[#2350B8] text-white flex items-center ${isLoading ? 'cursor-not-allowed bg-[#c2c4c8]' : 'cursor-pointer'}`} onClick={() => setIsLoading(true)}><FaCheck /></div>
                  <div className={`h-8 outline-none px-2 rounded-md border items-center flex ml-1 ${isLoading ?
                    "cursor-not-allowed bg-slate-50" :
                    "hover:bg-green-600 hover:text-white border-green-600 text-green-600 cursor-pointer"} `}
                    onClick={!isLoading ? onDownload : undefined}><SiMicrosoftexcel />
                  </div>
                </div>
              </div>
              {isLoading ? (
                <div>
                  <LoaderTable />
                </div>
              ) : <>
                <TabelProduksi data={dataTabel} allData={allData} query={query} reference={tableRef} />
              </>}
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default ProduksiPage;
