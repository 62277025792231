import React from 'react'
import { Link } from 'react-router-dom'

const LoginPage = () => {
    return (
        <div className='gap-2 flex'>
            Login
            <input type="text" className='border' />
            <Link to={"/signup"} style={{ textDecoration: 'none' }}><p>Daftar</p></Link>

        </div>
    )
}

export default LoginPage