import React, { useState } from 'react'
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
import { FaSort } from 'react-icons/fa';

const TabelUsers = (props) => {
    const [order, setOrder] = useState("Asc")
    const [data, setData] = useState(props.data)
    // const data = props.data;
    const query = props.query;
    const jenis = props.jenis;
    const plant = props.plant;
    const pcs = props.pcs;
    const totalKgArray = [];
    const totalPcsArray = [];
    const sorting = (col) => {
        if (order === "Asc") {
            const sortedData = [...data].sort((a, b) =>
                a[col] > b[col] ? 1 : -1
            );
            setData(sortedData);
            setOrder("Desc");
        } else {
            const sortedData = [...data].sort((a, b) =>
                a[col] < b[col] ? 1 : -1
            );
            setData(sortedData);
            setOrder("Asc");
        }
    }
    return (
        <>
            <Sheet sx={{ height: "fit", maxHeight: "75vh", overflow: "auto", }}>
                <Table
                    borderAxis="both-between"
                    stripe="odd"
                    hoverRow
                    size="sm"
                    stickyHeader
                    stickyFooter
                    sx={{
                        "--TableCell-paddingY": "2px",
                        "--TableCell-paddingX": "0px",
                        "--TableCell-height": "23px",
                        "--Table-headerUnderlineThickness": "1px",
                    }}
                >
                    <thead>
                        <tr style={{ color: "white", fontSize: "12px", fontWeight: "100", textAlign: "center", }}>
                            <th style={{ width: 200, backgroundColor: "#8ca2f5", textAlign: "center", color: "white", verticalAlign: "middle", }}>
                                <div className='w-full text-center flex items-center justify-center gap-1 cursor-pointer'>Username<FaSort /></div>
                            </th>
                            <th style={{ width: 120, backgroundColor: "#8ca2f5", textAlign: "center", color: "white", verticalAlign: "middle", }} >First Name</th>
                            <th style={{ width: 120, backgroundColor: "#8ca2f5", textAlign: "center", color: "white", verticalAlign: "middle", }} >Last Name</th>
                            <th style={{ width: 120, backgroundColor: "#8ca2f5", textAlign: "center", color: "white", verticalAlign: "middle", }} >Email</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, index) => {
                            return (
                                <tr key={index} style={{ fontSize: "11px", fontWeight: "400", textAlign: "center", }}>
                                    <td style={{ textAlign: "left", paddingLeft: "20px", }}>{row.username}</td>
                                    <td>{row.first_name}</td>
                                    <td>{row.last_name}</td>
                                    <td>{row.email}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </Sheet>
        </>
    );
}

export default TabelUsers