import React from "react";
import { Link, NavLink } from "react-router-dom";

const SidebarMenu = (props) => {
  const IconComponent = props.icon;
  const isOpen = props.isOpen
  return (
    <>
      <NavLink style={{ textDecoration: 'none' }} to={props.path}>
        {({ isActive }) => (
          <div className="flex items-center py-[10px]">
            <div
              className={`items-center gap-1 p-2 w-full rounded-xl flex cursor-pointer text-[#2350B8] ${isActive
                ? "bg-[#0950C3] text-white"
                : "duration-100 hover:bg-[#0950C3] hover:text-white"
                }`}
            >
              <IconComponent />
              <p className={`text-[14px] leading-[20px] font-normal ${!isOpen && 'hidden'} overflow-x-hidden`}>
                {props.text}
              </p>
            </div>
          </div>
        )}
      </NavLink>
    </>
  );
};

export default SidebarMenu;
