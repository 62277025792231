import React from "react";
import { MagnifyingGlass } from "react-loader-spinner";

const LoaderTable = () => {
  return (
    <>
      <div className="mt-1 w-full mx-auto h-fit">
        <div className="border bg-white shadow-sm rounded-[4px]">
          <div className="container-fluid p-2">
            <div className="table-responsive">
              <MagnifyingGlass
                visible={true}
                height="65"
                width="65"
                ariaLabel="MagnifyingGlass-loading"
                wrapperStyle={{ margin: "auto" }}
                wrapperClass="MagnifyingGlass-wrapper"
                glassColor="#def5fc"
                color="#8ca2f5"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoaderTable;
