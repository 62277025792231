import React from 'react';

const customStylesTabel = {
    headRow: {
        style: {
            backgroundColor: '#8ca2f5',
            paddingLeft: '0',
            paddingRight: '0',
        },
    },
    headCells: {
        style: {
            color: 'white',
            fontSize: '12px',
            paddingLeft: '0',
            paddingRight: '0',
            height: '40px',
            justifyContent: 'center',
        },
    },
    rows: {
        style: {
            fontSize: '11px',
            paddingLeft: '0',
            paddingRight: '0',
            marginLeft: '0',
        },
    },
    cells: {
        style: {
            paddingLeft: '0',
            paddingRight: '0',
            justifyContent: 'center',
        },
    },
};

export default customStylesTabel