import React, { useEffect, useRef, useState } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import LoaderTable from "../../molecules/LoaderTable";
import TabelStock from "../../components/Table/TabelStock";
import moment from "moment";
import { FaCheck } from "react-icons/fa";
import TextInput from "../../molecules/TextInput";
import DateInput from "../../molecules/DateInput";
import { SiMicrosoftexcel } from "react-icons/si";
import { useDownloadExcel } from 'react-export-table-to-excel';

const StockPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [allData, setAllData] = useState([]);
  const [jenis, setJenis] = useState("Fresh");
  const [plantFresh, setPlantFresh] = useState(["rpa", "bosco", "tirta", "kraton", "kiat"]);
  const [pcsFresh, setPcsFresh] = useState(["pcs_rpa", "pcs_bosco", "pcs_tirta", "pcs_kraton", "pcs_kiat"]);
  const [plantFrozen, setPlantFrozen] = useState(["rpa_frozen", "bosco_frozen", "tirta_frozen", "kraton_frozen", "kiat_frozen"]);
  const [pcsFrozen, setPcsFrozen] = useState(["pcs_rpa_frozen", "pcs_bosco_frozen", "pcs_tirta_frozen", "pcs_kraton_frozen", "pcs_kiat_frozen"]);
  const [query, setQuery] = useState("");
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const tableRef = useRef(null);


  const apiUrl = process.env.REACT_APP_API_URL_STOCK + moment(date).format('YYYYMMDD') //moment() auto hari ini
  function getFromChildText(data) {
    setQuery(data)
  }

  function getFromChildDate(data) {
    setDate(data)
    // console.log("date", date)
  }

  useEffect(() => {
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setAllData(data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [isLoading]);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `Stock ${jenis}`,
    sheet: `${jenis}`
  })
  return (
    <>
      <div className="mt-[10px] w-full max-w-[98%] mx-auto h-fit font-light">
        <div className="basis-[70%] border bg-white shadow-md rounded-[4px]">
          <div className="container-fluid p-2">
            <div className="table-responsive">
              <div className="flex">
                <div className="flex">
                  <div
                    className={` ${isLoading ? 'hidden' : 'px-4 py-2 rounded-t-md cursor-pointer hover:bg-[#2350B8] hover:text-white'} ${jenis === "Fresh"
                      ? "bg-[#2350B8] text-white"
                      : "border border-b-0 border-[#2350B8] text-[#2350B8]"
                      }`}
                    onClick={() => setJenis("Fresh")}
                  >
                    Fresh
                  </div>
                  <div
                    className={` ${isLoading ? 'hidden' : 'px-4 py-2 rounded-t-md cursor-pointer hover:bg-[#2350B8] hover:text-white'} ${jenis === "Frozen"
                      ? "bg-[#2350B8] text-white"
                      : "border border-b-0 border-[#2350B8] text-[#2350B8]"
                      }`}
                    onClick={() => setJenis("Frozen")}
                  >
                    Frozen
                  </div>
                </div>
                <div className="ml-auto flex items-center">
                  <div className="flex items-center rounded-[5px] mr-1">
                    <TextInput getFromChild={getFromChildText} isLoading={isLoading} />
                  </div>
                  <div className="flex items-center rounded-l-md border hover:border-y-[#adadaf] hover:border-l-[#adadaf]">
                    <DateInput getFromChild={getFromChildDate} isLoading={isLoading} tgl={date} />
                  </div>
                  <div className={`px-2 h-[33px] rounded-r-md bg-[#2350B8] text-white flex items-center ${isLoading ? 'cursor-not-allowed bg-[#c2c4c8]' : 'cursor-pointer'}`} onClick={() => setIsLoading(true)}><FaCheck /></div>
                  <div className={`h-8 outline-none px-2 rounded-md border items-center flex ml-1 ${isLoading ?
                    "cursor-not-allowed bg-slate-50" :
                    "hover:bg-green-600 hover:text-white border-green-600 text-green-600 cursor-pointer"} `}
                    onClick={!isLoading ? onDownload : undefined}><SiMicrosoftexcel />
                  </div>
                </div>
              </div>
              {isLoading ? (
                <div>
                  <LoaderTable />
                </div>
              ) : <>
                {jenis === "Fresh" && <TabelStock data={allData} jenis="Fresh" plant={plantFresh} pcs={pcsFresh} query={query} reference={tableRef} />}
                {jenis === "Frozen" && <TabelStock data={allData} jenis="Frozen" plant={plantFrozen} pcs={pcsFrozen} query={query} reference={tableRef} />}
              </>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StockPage;
