import React from 'react'
import TabelKosong from '../components/Table/TabelKosong';

const ModalTable = (props) => {
    const allData = props.data;
    const nama = props.namaTabel
    const dataSpesifik = allData[nama]
    const judulTabel = props.judulTabel
    // console.log(dataSpesifik);
    return (
        <div>
            <dialog id="my_modal_2" className="modal">
                <div className="modal-box" style={{ maxWidth: '70vw' }}>
                    <TabelKosong data={dataSpesifik} namaTabel={judulTabel} />
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button>close</button>
                </form>
            </dialog >
        </div >
    )
}
export default ModalTable