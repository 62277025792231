import React, { useState } from "react";
import DataTable from "react-data-table-component";
import customStylesTabel from "./CustomStylesTabel";
import { DateRangePicker } from "rsuite";
import LoaderTable from "../../molecules/LoaderTable";
import "rsuite/dist/rsuite.min.css";

const columns_modal = [
  {
    name: "Code",
    selector: (row) => row.code,
    sortable: true,
    width: "100px",
  },
  {
    name: "Deskripsi",
    selector: (row) => row.description,
    sortable: true,
  },
  {
    name: "Tanggal",
    selector: (row) => row.ymd,
    sortable: true,
  },
  {
    name: "Pcs",
    selector: (row) => row.pcs,
    sortable: true,
    width: "80px",
  },
  {
    name: "Qty",
    selector: (row) => row.qty,
    sortable: true,
    width: "80px",
  },
  {
    name: "Rendemen",
    selector: (row) => row.rendemen,
    sortable: true,
    width: "80px",
  },
];

const TabelKosong = (props) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [isLoading, setIsLoading] = useState(true);

  const value = props.data;
  const columns = props.columns;

  const handleDateChange = (value) => {
    setDateRange(value);
    // console.log('Formatted Start Date:', formatDateApi(value[0]));
    // console.log('Formatted End Date:', formatDateApi(value[1]));
  };

  return (
    <div className="mt-[22px] w-full max-w-[98%] mx-auto h-fit">
      <div className="basis-[70%] border bg-white shadow-md rounded-[4px]">
        <div className="bg-[#F8F9FC] flex items-center justify-between py-[15px] px-[20px] border-b-[1px] border-[#EDEDED] ">
          <h2 className="text-[#4e73df] text-[16px] leading-[19px] font-bold">
            {props.namaTabel}
          </h2>
        </div>
        <div className="container-fluid p-4">
          <div className="table-responsive p-0 pb-2">
            <DataTable
              columns={props.columns ? props.columns : columns_modal}
              data={value}
              customStyles={customStylesTabel}
              dense
              fixedHeader
              fixedHeaderScrollHeight="300px"
              highlightOnHover
              responsive
              striped
              progressPending={props.loading && isLoading}
              progressComponent={props.loading && <LoaderTable />}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabelKosong;
