import { Outlet, Navigate, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Header from "./components/Header/Header";
import Sidebar from "./components/Sidebar/Sidebar";
import { useEffect, useState } from "react";
import { Bars } from "react-loader-spinner";

function App() {
  const [isAuth, setIsAuth] = useState();
  const [datanya, setDatanya] = useState();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const username = queryParams.get("username");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        //if params tidak ada, maka cek sessionstorage, lalu ke indeks
        if (!username) {
          if (sessionStorage.getItem("data")) {
            setDatanya(JSON.parse(sessionStorage.getItem("data")));
            setIsAuth(true);
          } else {
            window.location.replace("https://appdmc.com");
          }
        } else {
          navigate("/");
          if (sessionStorage.getItem("data")) {
            setDatanya(JSON.parse(sessionStorage.getItem("data")));
            setIsAuth(true);
          } else {
            const bodyContent = new URLSearchParams();
            bodyContent.append("username", username);
            const headersList = {
              Accept: "*/*",
              "Content-Type": "application/x-www-form-urlencoded",
            };
            const response = await fetch(
              "https://appdmc.com/api/rpa/validasi_rpa.php",
              // "http://of.fekusa.com:4001/rpa_sync/validasi_rpa.php",
              {
                method: "POST",
                body: bodyContent,
                headers: headersList,
              }
            );
            const data = await response.json();
            setIsAuth(data.status);
            setDatanya(JSON.parse(data.data));
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  if (isAuth === undefined) {
    return (
      <div className="h-screen w-full flex justify-center items-center">
        <Bars
          height="40"
          width="40"
          color="#0950C3"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  } else if (isAuth === false) {
    window.location.replace("https://appdmc.com");
  } else if (isAuth === true) {
    sessionStorage.setItem("data", JSON.stringify(datanya));
  }
  return (
    <>
      {isAuth && (
        <div className="flex w-[100vw]">
          <div className="flex">
            <Sidebar />
          </div>
          <div className="overflow-scroll h-[100vh] w-full">
            <Header />
            <div>
              <Outlet></Outlet>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default App;
