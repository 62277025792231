import React, { useEffect, useState } from "react";
import { FaEnvelope, FaRegBell, FaSearch, FaSignOutAlt, FaUser } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import profile from "../../assets/profile.png";
import { Link, NavLink } from "react-router-dom";

const Header = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (sessionStorage.getItem("data")) {
      setData(JSON.parse(sessionStorage.getItem("data")));
    } else {
      console.log("data tidak ada")
    }
  }, []);

  return (
    <>
      {data &&
        <div className="flex items-center justify-between h-[70px] shadow-lg px-[25px] w-full">
          <div className="flex items-center gap-[20px] ml-auto">
            <div className="flex items-center gap-[25px] border-r-[1px] pr-[25px]">
              <FaRegBell />
              <FaEnvelope />
            </div>
            <div className="flex items-center gap-[15px] relative dropdown dropdown-hover dropdown-end cursor-default" tabIndex={0}>
              <p>{data.first_name}</p>
              {/* <p>sdfsfd</p> */}
              <div>
                <div className="h-[50px] w-[50px] rounded-full bg-[#4E73DF] flex items-center justify-center relative z-40">
                  <img src={profile} alt="" />
                </div>
                <div tabIndex={0}
                  className="bg-white border w-[150px] absolute z-20 right-0 pt-[15px] pl-[15px] rounded-md border-[#c3cee8] dropdown-content p-3"
                >
                  {/* <Link to={"/profile"} style={{ textDecoration: 'none' }}> */}
                  <div className="cursor-pointer hover:text-white hover:bg-blue-500 w-full p-1 rounded-md flex items-center gap-1 duration-100 text-gray-800 mb-1">
                    <FaUser />
                    <p>Profile</p>
                  </div>
                  {/* </Link> */}
                  {/* <Link to={"/login"} style={{ textDecoration: 'none' }}> */}
                  <div className="cursor-pointer hover:text-white hover:bg-blue-500 w-full p-1 rounded-md flex items-center gap-1 duration-100 text-gray-800 mb-1">
                    <IoMdSettings />
                    <p>Settings</p>
                  </div>
                  {/* </Link> */}
                  <div className="cursor-pointer hover:text-white hover:bg-blue-500 w-full p-1 rounded-md flex items-center gap-1 duration-100 text-gray-800 mb-1"
                    onClick={() => {
                      window.location.replace("https://appdmc.com");
                      sessionStorage.removeItem("data");
                    }}>
                    <FaSignOutAlt />
                    <p>Log out </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      }
    </>
  );
};

export default Header;
