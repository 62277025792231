import React, { useEffect, useState } from 'react'
import TextInput from '../../molecules/TextInput';
import DateInput from '../../molecules/DateInput';
import LoaderTable from '../../molecules/LoaderTable';
import TabelStock from '../../components/Table/TabelStock';
import { FaCheck } from 'react-icons/fa';
import moment from "moment";
import TabelUsers from '../../components/Table/TabelUsers';

const UsersPage = () => {
    const [dataUsers, setDataUsers] = useState();
    const [isAuth, setIsAuth] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const [allData, setAllData] = useState([]);
    const [jenis, setJenis] = useState("Fresh");
    const [plantFresh, setPlantFresh] = useState(["rpa", "bosco", "tirta", "kraton", "kiat"]);
    const [pcsFresh, setPcsFresh] = useState(["pcs_rpa", "pcs_bosco", "pcs_tirta", "pcs_kraton", "pcs_kiat"]);
    const [plantFrozen, setPlantFrozen] = useState(["rpa_frozen", "bosco_frozen", "tirta_frozen", "kraton_frozen", "kiat_frozen"]);
    const [pcsFrozen, setPcsFrozen] = useState(["pcs_rpa_frozen", "pcs_bosco_frozen", "pcs_tirta_frozen", "pcs_kraton_frozen", "pcs_kiat_frozen"]);
    const [query, setQuery] = useState("");
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'));

    function getFromChildText(data) {
        setQuery(data)
    }

    function getFromChildDate(data) {
        setDate(data)
        // console.log("date", date)
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                let bodyContent = `username=engineer&function=read`
                const headersList = {
                    Accept: "*/*",
                    "Content-Type": "application/x-www-form-urlencoded",
                };
                const response = await fetch(
                    // "https://appdmc.com/api/rpa/validasi_rpa.php",
                    "http://of.fekusa.com:4001/rpa_sync/backend/user.php",
                    {
                        method: "POST",
                        body: bodyContent,
                        headers: headersList,
                    }
                );
                const data = await response.json();
                setDataUsers(JSON.parse(data.data));
                setIsLoading(false)
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    return (
        <>
            <div className="mt-[10px] w-full max-w-[98%] mx-auto h-fit font-light">
                <div className="basis-[70%] border bg-white shadow-md rounded-[4px]">
                    <div className="container-fluid p-2">
                        <div className="table-responsive">
                            {isLoading ? (
                                <div>
                                    <LoaderTable />
                                </div>
                            ) : <>
                                {jenis === "Fresh" && <TabelUsers data={dataUsers} />}
                            </>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UsersPage