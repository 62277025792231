import React, { useState } from 'react'
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
import { FaSort } from 'react-icons/fa';

const TabelStock = (props) => {
    const [order, setOrder] = useState("Asc")
    const [data, setData] = useState(props.data)
    // const data = props.data;
    const query = props.query;
    const jenis = props.jenis;
    const plant = props.plant;
    const pcs = props.pcs;
    const totalKgArray = [];
    const totalPcsArray = [];

    const totalKgRpaArr = [];
    const totalKgBoscoArr = [];
    const totalKgTirtaArr = [];
    const totalKgKratonArr = [];
    const totalKgKiatArr = [];

    const totalPcsRpaArr = [];
    const totalPcsBoscoArr = [];
    const totalPcsTirtaArr = [];
    const totalPcsKratonArr = [];
    const totalPcsKiatArr = [];
    const sorting = (col) => {
        if (order === "Asc") {
            const sortedData = [...data].sort((a, b) =>
                a[col] > b[col] ? 1 : -1
            );
            setData(sortedData);
            setOrder("Desc");
        } else {
            const sortedData = [...data].sort((a, b) =>
                a[col] < b[col] ? 1 : -1
            );
            setData(sortedData);
            setOrder("Asc");
        }
    }
    return (
        <>
            <Sheet sx={{ height: "fit", maxHeight: "75vh", overflow: "auto", }}>
                <Table
                    borderAxis="both-between"
                    stripe="odd"
                    hoverRow
                    size="sm"
                    stickyHeader
                    stickyFooter
                    sx={{
                        "--TableCell-paddingY": "2px",
                        "--TableCell-paddingX": "0px",
                        "--TableCell-height": "23px",
                        "--Table-headerUnderlineThickness": "1px",
                    }}
                    ref={props.reference}
                >
                    <thead>
                        <tr style={{ color: "white", fontSize: "12px", fontWeight: "100", textAlign: "center", }}>
                            {/* <th style={{ width: 200, backgroundColor: "#8ca2f5", textAlign: "center", color: "white", verticalAlign: "middle", }} rowSpan={2}>Nama Barang <FaSort /></th> */}
                            <th style={{ width: 200, backgroundColor: "#8ca2f5", textAlign: "center", color: "white", verticalAlign: "middle", }} rowSpan={2}>
                                <div className='w-full text-center flex items-center justify-center gap-1 cursor-pointer' onClick={() => sorting("full_name")}>Nama Barang <FaSort /></div>
                            </th>
                            <th style={{ width: 120, backgroundColor: "#8ca2f5", textAlign: "center", color: "white", verticalAlign: "middle", }} colSpan={2}>RPA {jenis}</th>
                            <th style={{ width: 120, backgroundColor: "#8ca2f5", textAlign: "center", color: "white", verticalAlign: "middle", }} colSpan={2}>Bosco {jenis}</th>
                            <th style={{ width: 120, backgroundColor: "#8ca2f5", textAlign: "center", color: "white", verticalAlign: "middle", }} colSpan={2}>Tirta {jenis}</th>
                            <th style={{ width: 120, backgroundColor: "#8ca2f5", textAlign: "center", color: "white", verticalAlign: "middle", }} colSpan={2}>Kraton {jenis}</th>
                            <th style={{ width: 120, backgroundColor: "#8ca2f5", textAlign: "center", color: "white", verticalAlign: "middle", }} colSpan={2}>Kiat {jenis}</th>
                            <th style={{ width: 120, backgroundColor: "#8ca2f5", textAlign: "center", color: "white", verticalAlign: "middle", }} colSpan={2}>Total Per Bahan</th>
                        </tr>
                        <tr>
                            <th style={{ backgroundColor: "#8ca2f5", color: "white" }}>
                                <div className='w-full text-center flex items-center justify-center gap-1 cursor-pointer' onClick={() => sorting(plant[0])}>KG <FaSort /></div>
                            </th>
                            <th style={{ backgroundColor: "#8ca2f5", color: "white" }}>
                                <div className='w-full text-center flex items-center justify-center gap-1 cursor-pointer' onClick={() => sorting(pcs[0])}>PCS <FaSort /></div>
                            </th>
                            <th style={{ backgroundColor: "#8ca2f5", color: "white" }}>
                                <div className='w-full text-center flex items-center justify-center gap-1 cursor-pointer' onClick={() => sorting(plant[1])}>KG <FaSort /></div>
                            </th>
                            <th style={{ backgroundColor: "#8ca2f5", color: "white" }}>
                                <div className='w-full text-center flex items-center justify-center gap-1 cursor-pointer' onClick={() => sorting(pcs[1])}>PCS <FaSort /></div>
                            </th>
                            <th style={{ backgroundColor: "#8ca2f5", color: "white" }}>
                                <div className='w-full text-center flex items-center justify-center gap-1 cursor-pointer' onClick={() => sorting(plant[2])}>KG <FaSort /></div>
                            </th>
                            <th style={{ backgroundColor: "#8ca2f5", color: "white" }}>
                                <div className='w-full text-center flex items-center justify-center gap-1 cursor-pointer' onClick={() => sorting(pcs[2])}>PCS <FaSort /></div>
                            </th>
                            <th style={{ backgroundColor: "#8ca2f5", color: "white" }}>
                                <div className='w-full text-center flex items-center justify-center gap-1 cursor-pointer' onClick={() => sorting(plant[3])}>KG <FaSort /></div>
                            </th>
                            <th style={{ backgroundColor: "#8ca2f5", color: "white" }}>
                                <div className='w-full text-center flex items-center justify-center gap-1 cursor-pointer' onClick={() => sorting(pcs[3])}>PCS <FaSort /></div>
                            </th>
                            <th style={{ backgroundColor: "#8ca2f5", color: "white" }}>
                                <div className='w-full text-center flex items-center justify-center gap-1 cursor-pointer' onClick={() => sorting(plant[4])}>KG <FaSort /></div>
                            </th>
                            <th style={{ backgroundColor: "#8ca2f5", color: "white" }}>
                                <div className='w-full text-center flex items-center justify-center gap-1 cursor-pointer' onClick={() => sorting(pcs[4])}>PCS <FaSort /></div>
                            </th>
                            <th style={{ backgroundColor: "#8ca2f5", color: "white" }}>
                                <div className='w-full text-center flex items-center justify-center gap-1 cursor-pointer' onClick={() => sorting(totalKgArray)}>KG <FaSort /></div>
                            </th>
                            <th style={{ backgroundColor: "#8ca2f5", color: "white" }}>
                                <div className='w-full text-center flex items-center justify-center gap-1 cursor-pointer' onClick={() => sorting(totalPcsArray)}>PCS <FaSort /></div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.filter((item) =>
                            item.full_name.toLowerCase().includes(query)
                        ).map((row, index) => {
                            const totalKg = row[plant[0]] + row[plant[1]] + row[plant[2]] + row[plant[3]] + row[plant[4]];
                            const totalPcs = row[pcs[0]] + row[pcs[1]] + row[pcs[2]] + row[pcs[3]] + row[pcs[4]];

                            totalKgArray.push(totalKg);
                            totalPcsArray.push(totalPcs);

                            totalKgRpaArr.push(row[plant[0]]);
                            totalKgBoscoArr.push(row[plant[1]]);
                            totalKgTirtaArr.push(row[plant[2]]);
                            totalKgKratonArr.push(row[plant[3]]);
                            totalKgKiatArr.push(row[plant[4]]);

                            totalPcsRpaArr.push(row[pcs[0]]);
                            totalPcsBoscoArr.push(row[pcs[1]]);
                            totalPcsTirtaArr.push(row[pcs[2]]);
                            totalPcsKratonArr.push(row[pcs[3]]);
                            totalPcsKiatArr.push(row[pcs[4]]);
                            return (
                                <tr key={index} style={{ fontSize: "11px", fontWeight: "400", textAlign: "center", }}>
                                    <td style={{ textAlign: "left", paddingLeft: "20px", }}>{row.full_name}</td>
                                    <td>{row[plant[0]]}</td>
                                    <td>{row[pcs[0]]}</td>
                                    <td>{row[plant[1]]}</td>
                                    <td>{row[pcs[1]]}</td>
                                    <td>{row[plant[2]]}</td>
                                    <td>{row[pcs[2]]}</td>
                                    <td>{row[plant[3]]}</td>
                                    <td>{row[pcs[3]]}</td>
                                    <td>{row[plant[4]]}</td>
                                    <td>{row[pcs[4]]}</td>
                                    <td>{totalKg.toFixed(2)}</td>
                                    <td>{totalPcs.toFixed(2)}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                    <tfoot>
                        <tr style={{ fontSize: "11px", fontWeight: "500", textAlign: "center", }}>
                            <th scope="row" style={{ fontSize: "12px", fontWeight: "500", backgroundColor: "#b9d2fa", textAlign: "center", }}>Total</th>
                            <td style={{ backgroundColor: "#b9d2fa", }}>{totalKgRpaArr.reduce((total, item) => total + item, 0).toFixed(2)}</td>
                            <td style={{ backgroundColor: "#b9d2fa", }}>{totalPcsRpaArr.reduce((total, item) => total + item, 0).toFixed(2)}</td>
                            <td style={{ backgroundColor: "#b9d2fa", }}>{totalKgBoscoArr.reduce((total, item) => total + item, 0).toFixed(2)}</td>
                            <td style={{ backgroundColor: "#b9d2fa", }}>{totalPcsBoscoArr.reduce((total, item) => total + item, 0).toFixed(2)}</td>
                            <td style={{ backgroundColor: "#b9d2fa", }}>{totalKgTirtaArr.reduce((total, item) => total + item, 0).toFixed(2)}</td>
                            <td style={{ backgroundColor: "#b9d2fa", }}>{totalPcsTirtaArr.reduce((total, item) => total + item, 0).toFixed(2)}</td>
                            <td style={{ backgroundColor: "#b9d2fa", }}>{totalKgKratonArr.reduce((total, item) => total + item, 0).toFixed(2)}</td>
                            <td style={{ backgroundColor: "#b9d2fa", }}>{totalPcsKratonArr.reduce((total, item) => total + item, 0).toFixed(2)}</td>
                            <td style={{ backgroundColor: "#b9d2fa", }}>{totalKgKiatArr.reduce((total, item) => total + item, 0).toFixed(2)}</td>
                            <td style={{ backgroundColor: "#b9d2fa", }}>{totalPcsKiatArr.reduce((total, item) => total + item, 0).toFixed(2)}</td>
                            <td style={{ backgroundColor: "#b9d2fa", }}>{totalKgArray.reduce((total, item) => total + item, 0).toFixed(2)}</td>
                            <td style={{ backgroundColor: "#b9d2fa", }}>{totalPcsArray.reduce((total, item) => total + item, 0).toFixed(2)}</td>
                        </tr>
                    </tfoot>
                </Table>
            </Sheet>
        </>
    );
}

export default TabelStock