import React, { useEffect, useRef, useState } from "react";
import LoaderTable from "../../molecules/LoaderTable";
import TabelPenerimaan from "../../components/Table/TabelPenerimaan";
import TextInput from "../../molecules/TextInput";
import DateInput from "../../molecules/DateInput";
import moment from "moment";
import { FaCheck } from "react-icons/fa";
import { useDownloadExcel } from 'react-export-table-to-excel';
import { SiMicrosoftexcel } from "react-icons/si";


const PenerimaanPage = () => {
  const [datanya, setDatanya] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQuery] = useState("");
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const tableRef = useRef(null);

  const apiUrl = process.env.REACT_APP_API_URL_PENERIMAAN + moment(date).format('YYYYMMDD')

  function getFromChildText(data) {
    setQuery(data)
  }

  function getFromChildDate(data) {
    setDate(data)
    // console.log("date", date)
  }

  useEffect(() => {
    // fetch(`https://ismaf.mydmc.co.id/api/rpa/live-bird?ymd=20231214`)
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setDatanya(data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [isLoading]);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `Raw Materials`,
    sheet: 'Raw Materials'
  })
  return (
    <>
      <div className="mt-[10px] w-full max-w-[98%] mx-auto h-fit font-light">
        <div className="basis-[70%] border bg-white shadow-md rounded-[4px]">
          <div className="container-fluid p-2">
            <div className="table-responsive">
              <div className="flex">
                <div className="ml-auto flex items-center pb-1">
                  <div className="flex items-center rounded-[5px] mr-1">
                    <TextInput getFromChild={getFromChildText} isLoading={isLoading} />
                  </div>
                  <div className="flex items-center rounded-l-md border hover:border-y-[#adadaf] hover:border-l-[#adadaf]">
                    <DateInput getFromChild={getFromChildDate} isLoading={isLoading} tgl={date} />
                  </div>
                  <div className={`px-2 h-[33px] rounded-r-md bg-[#2350B8] text-white flex items-center ${isLoading ? 'cursor-not-allowed bg-[#c2c4c8]' : 'cursor-pointer'}`} onClick={() => setIsLoading(true)}><FaCheck /></div>
                  <div className={`h-8 outline-none px-2 rounded-md border items-center flex ml-1 ${isLoading ?
                    "cursor-not-allowed bg-slate-50" :
                    "hover:bg-green-600 hover:text-white border-green-600 text-green-600 cursor-pointer"} `}
                    onClick={!isLoading ? onDownload : undefined}><SiMicrosoftexcel />
                  </div>
                </div>
              </div>
              {isLoading ? (
                <div>
                  <LoaderTable />
                </div>
              ) : <>
                <TabelPenerimaan data={datanya} query={query} reference={tableRef} />
              </>}
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default PenerimaanPage;
