import React, { useRef, useState } from 'react'
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
import { FaSort } from 'react-icons/fa';

const TabelExpStock = (props) => {
    const [data, setData] = useState(props.data)
    const [order, setOrder] = useState("Asc")
    const query = props.query;
    const filter = props.filter;

    const totalBeginCollyArray = [];
    const totalBeginKgArray = [];
    const totalBeginPalletArray = [];

    const totalMutasiInCollyArray = [];
    const totalMutasiInKgArray = [];
    const totalMutasiInPalletArray = [];

    const totalMutasiOutCollyArray = [];
    const totalMutasiOutKgArray = [];
    const totalMutasiOutPalletArray = [];

    const totalAdjustmentCollyArray = [];
    const totalAdjustmentKgArray = [];
    const totalAdjustmentPalletArray = [];

    const totalEndingStockCollyArray = [];
    const totalEndingStockKgArray = [];
    const totalEndingStockPalletArray = [];

    function hitungJumlahHari(durasi) {
        const regex = /(\d+)TAHUN, (\d+)BULAN, (\d+)HARI/;
        const match = durasi.match(regex);
        if (durasi === "EXPIRED") {
            return 0;
        }
        const tahun = parseInt(match[1], 10);
        const bulan = parseInt(match[2], 10);
        const hari = parseInt(match[3], 10);
        const jumlahHari = tahun * 365 + bulan * 30 + hari;
        return jumlahHari;
    }

    const newData = data.map(item => {
        const durasi = item.shelf_life
        return { ...item, hari: hitungJumlahHari(durasi) };
    });

    const sorting = (col) => {
        if (order === "Asc") {
            const sortedData = [...newData].sort((a, b) =>
                a[col] > b[col] ? 1 : -1
            );
            setData(sortedData);
            setOrder("Desc");
        } else {
            const sortedData = [...newData].sort((a, b) =>
                a[col] < b[col] ? 1 : -1
            );
            setData(sortedData);
            setOrder("Asc");
        }
    }

    return (
        <>
            <Sheet
                sx={{
                    height: "fit",
                    maxHeight: "75vh",
                    overflow: "auto",
                }}>
                <Table
                    borderAxis="both-between"
                    stripe="odd"
                    hoverRow
                    size="sm"
                    stickyHeader
                    stickyFooter
                    sx={{
                        "--TableCell-paddingY": "2px",
                        "--TableCell-paddingX": "0px",
                        "--TableCell-height": "23px",
                        "--Table-headerUnderlineThickness": "1px",
                    }}
                    ref={props.reference}>
                    <thead>
                        <tr style={{ color: "white", fontSize: "12px", fontWeight: "100", textAlign: "center", }}>
                            <th style={{ width: 40, backgroundColor: "#8ca2f5", textAlign: "center", color: "white", verticalAlign: "middle", }} rowSpan={2}>
                                <div className='w-full text-center flex items-center justify-center gap-1 cursor-pointer' onClick={() => sorting("no")}>No <FaSort /></div>
                            </th>
                            <th style={{ width: 80, backgroundColor: "#8ca2f5", textAlign: "center", color: "white", verticalAlign: "middle", }} rowSpan={2}>
                                <div className='w-full text-center flex items-center justify-center gap-1 cursor-pointer' onClick={() => sorting("code_item")}>Kode Item <FaSort /></div>
                            </th>
                            <th style={{ width: 200, backgroundColor: "#8ca2f5", textAlign: "center", color: "white", verticalAlign: "middle", }} rowSpan={2}>
                                <div className='w-full text-center flex items-center justify-center gap-1 cursor-pointer' onClick={() => sorting("description")}>Deskripsi <FaSort /></div>
                            </th>
                            <th style={{ width: 120, backgroundColor: "#8ca2f5", textAlign: "center", color: "white", verticalAlign: "middle", }} rowSpan={2}>
                                <div className='w-full text-center flex items-center justify-center gap-1 cursor-pointer' onClick={() => sorting("category")}>Kategori <FaSort /></div>
                            </th>
                            <th style={{ width: 90, backgroundColor: "#8ca2f5", textAlign: "center", color: "white", verticalAlign: "middle", }} rowSpan={2}>
                                <div className='w-full text-center flex items-center justify-center gap-1 cursor-pointer' onClick={() => sorting("tgl_masuk")}>
                                    <div className="row">
                                        <div>Tgl</div>
                                        <div>Masuk</div>
                                    </div>
                                    <FaSort />
                                </div>
                            </th>
                            <th style={{ width: 90, backgroundColor: "#8ca2f5", textAlign: "center", color: "white", verticalAlign: "middle", }} rowSpan={2}>
                                <div className='w-full text-center flex items-center justify-center gap-1 cursor-pointer' onClick={() => sorting("expired_date")}>
                                    <div className="row">
                                        <div>Expired</div>
                                        <div>Date</div>
                                    </div>
                                    <FaSort />
                                </div>
                            </th>
                            <th style={{ width: 90, backgroundColor: "#8ca2f5", textAlign: "center", color: "white", verticalAlign: "middle", }} rowSpan={2}>
                                <div className='w-full text-center flex items-center justify-center gap-1 cursor-pointer' onClick={() => sorting("aging_days")}>
                                    <div className="row">
                                        <div>Aging</div>
                                        <div>Days</div>
                                    </div>
                                    <FaSort />
                                </div>
                            </th>
                            <th style={{ textAlign: "center", width: 140, backgroundColor: "#8ca2f5", color: "white", }} colSpan={2}>Shelf Life</th>
                            <th style={{ width: 150, backgroundColor: "#8ca2f5", textAlign: "center", color: "white", verticalAlign: "middle", }} rowSpan={2}>
                                <div className='w-full text-center flex items-center justify-center gap-1 cursor-pointer' onClick={() => sorting("hari")}>Shelf Life <FaSort /></div>
                            </th>
                            {/* <th style={{ width: 150, backgroundColor: "#8ca2f5", textAlign: "center", color: "white", verticalAlign: "middle", }} rowSpan={2}>
                                <div className='w-full text-center flex items-center justify-center gap-1 cursor-pointer' onClick={() => sorting("hari")}>Hari<FaSort /></div>
                            </th> */}
                            <th style={{ textAlign: "center", width: 200, backgroundColor: "#8ca2f5", color: "white", }} colSpan={3}>Beginning</th>
                            <th style={{ textAlign: "center", width: 200, backgroundColor: "#8ca2f5", color: "white", }} colSpan={3}>Mutasi In</th>
                            <th style={{ textAlign: "center", width: 200, backgroundColor: "#8ca2f5", color: "white", }} colSpan={3}>Mutasi Out</th>
                            <th style={{ textAlign: "center", width: 200, backgroundColor: "#8ca2f5", color: "white", }} colSpan={3}>Adjustment</th>
                            <th style={{ textAlign: "center", width: 200, backgroundColor: "#8ca2f5", color: "white", }} colSpan={3}>Ending Stock</th>
                            <th style={{ width: 80, backgroundColor: "#8ca2f5", textAlign: "center", color: "white", verticalAlign: "middle", }} rowSpan={2}>
                                <div className='w-full text-center flex items-center justify-center  cursor-pointer' onClick={() => sorting("hasil_sampling")}>
                                    <div className="row">
                                        <div>Hasil</div>
                                        <div>Sampling</div>
                                    </div>
                                    <FaSort />
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th style={{ backgroundColor: "#8ca2f5", color: "white" }}>
                                <div className='w-full text-center flex items-center justify-center gap-1 cursor-pointer' onClick={() => sorting("shelf_life_days")}>Days <FaSort /></div>
                            </th>
                            <th style={{ backgroundColor: "#8ca2f5", color: "white" }}>
                                <div className='w-full text-center flex items-center justify-center gap-1 cursor-pointer' onClick={() => sorting("shelf_life_month")}>Month <FaSort /></div>
                            </th>
                            <th style={{ backgroundColor: "#8ca2f5", color: "white" }}>
                                <div className='w-full text-center flex items-center justify-center gap-1 cursor-pointer' onClick={() => sorting("beginning_colly")}>Colly <FaSort /></div>
                            </th>
                            <th style={{ backgroundColor: "#8ca2f5", color: "white" }}>
                                <div className='w-full text-center flex items-center justify-center gap-1 cursor-pointer' onClick={() => sorting("beginning_kg")}>Kg <FaSort /></div>
                            </th>
                            <th style={{ backgroundColor: "#8ca2f5", color: "white" }}>
                                <div className='w-full text-center flex items-center justify-center gap-1 cursor-pointer' onClick={() => sorting("beginning_pallet")}>Pallet <FaSort /></div>
                            </th>
                            <th style={{ backgroundColor: "#8ca2f5", color: "white" }}>
                                <div className='w-full text-center flex items-center justify-center gap-1 cursor-pointer' onClick={() => sorting("mutasi_in_colly")}>Colly <FaSort /></div>
                            </th>
                            <th style={{ backgroundColor: "#8ca2f5", color: "white" }}>
                                <div className='w-full text-center flex items-center justify-center gap-1 cursor-pointer' onClick={() => sorting("mutasi_in_kg")}>Kg <FaSort /></div>
                            </th>
                            <th style={{ backgroundColor: "#8ca2f5", color: "white" }}>
                                <div className='w-full text-center flex items-center justify-center gap-1 cursor-pointer' onClick={() => sorting("mutasi_in_pallet")}>Pallet <FaSort /></div>
                            </th>
                            <th style={{ backgroundColor: "#8ca2f5", color: "white" }}>
                                <div className='w-full text-center flex items-center justify-center gap-1 cursor-pointer' onClick={() => sorting("mutasi_out_colly")}>Colly <FaSort /></div>
                            </th>
                            <th style={{ backgroundColor: "#8ca2f5", color: "white" }}>
                                <div className='w-full text-center flex items-center justify-center gap-1 cursor-pointer' onClick={() => sorting("mutasi_out_kg")}>Kg <FaSort /></div>
                            </th>
                            <th style={{ backgroundColor: "#8ca2f5", color: "white" }}>
                                <div className='w-full text-center flex items-center justify-center gap-1 cursor-pointer' onClick={() => sorting("mutasi_out_pallet")}>Pallet <FaSort /></div>
                            </th>
                            <th style={{ backgroundColor: "#8ca2f5", color: "white" }}>
                                <div className='w-full text-center flex items-center justify-center gap-1 cursor-pointer' onClick={() => sorting("ending_stock_colly")}>Colly <FaSort /></div>
                            </th>
                            <th style={{ backgroundColor: "#8ca2f5", color: "white" }}>
                                <div className='w-full text-center flex items-center justify-center gap-1 cursor-pointer' onClick={() => sorting("ending_stock_kg")}>Kg <FaSort /></div>
                            </th>
                            <th style={{ backgroundColor: "#8ca2f5", color: "white" }}>
                                <div className='w-full text-center flex items-center justify-center gap-1 cursor-pointer' onClick={() => sorting("ending_stock_pallet")}>Pallet <FaSort /></div>
                            </th>
                            <th style={{ backgroundColor: "#8ca2f5", color: "white" }}>
                                <div className='w-full text-center flex items-center justify-center gap-1 cursor-pointer' onClick={() => sorting("ending_stock_colly")}>Colly <FaSort /></div>
                            </th>
                            <th style={{ backgroundColor: "#8ca2f5", color: "white" }}>
                                <div className='w-full text-center flex items-center justify-center gap-1 cursor-pointer' onClick={() => sorting("ending_stock_kg")}>Kg <FaSort /></div>
                            </th>
                            <th style={{ backgroundColor: "#8ca2f5", color: "white" }}>
                                <div className='w-full text-center flex items-center justify-center gap-1 cursor-pointer' onClick={() => sorting("ending_stock_pallet")}>Pallet <FaSort /></div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {newData.filter((item) =>
                            item.description.toLowerCase().includes(query) && (filter === "expired" || filter === "" ? item.shelf_life.toLowerCase().includes(filter) : item.hari < filter)
                        ).map((row, index) => {
                            totalBeginCollyArray.push(row.beginning_colly);
                            totalBeginKgArray.push(row.beginning_kg);
                            totalBeginPalletArray.push(row.beginning_pallet);

                            totalMutasiInCollyArray.push(row.mutasi_in_colly);
                            totalMutasiInKgArray.push(row.mutasi_in_kg);
                            totalMutasiInPalletArray.push(row.mutasi_in_pallet);

                            totalMutasiOutCollyArray.push(row.mutasi_out_colly);
                            totalMutasiOutKgArray.push(row.mutasi_out_kg);
                            totalMutasiOutPalletArray.push(row.mutasi_out_pallet);

                            totalAdjustmentCollyArray.push(row.adjustment_colly);
                            totalAdjustmentKgArray.push(row.adjustment_kg);
                            totalAdjustmentPalletArray.push(row.adjustment_pallet);

                            totalEndingStockCollyArray.push(row.ending_stock_colly);
                            totalEndingStockKgArray.push(row.ending_stock_kg);
                            totalEndingStockPalletArray.push(row.ending_stock_pallet);

                            return (
                                <tr key={index} style={{ fontSize: "11px", fontWeight: "400", textAlign: "center" }}>
                                    <td style={{ color: row.hari === 0 ? "red" : row.hari < 90 ? "#e615d4" : row.hari < 180 && row.hari > 90 ? "blue" : "black" }}>{row.no}</td>
                                    <td style={{ color: row.hari === 0 ? "red" : row.hari < 90 ? "#e615d4" : row.hari < 180 && row.hari > 90 ? "blue" : "black" }}>{row.code_item}</td>
                                    <td style={{ color: row.hari === 0 ? "red" : row.hari < 90 ? "#e615d4" : row.hari < 180 && row.hari > 90 ? "blue" : "black" }}>{row.description}</td>
                                    <td style={{ color: row.hari === 0 ? "red" : row.hari < 90 ? "#e615d4" : row.hari < 180 && row.hari > 90 ? "blue" : "black" }}>{row.category}</td>
                                    <td style={{ color: row.hari === 0 ? "red" : row.hari < 90 ? "#e615d4" : row.hari < 180 && row.hari > 90 ? "blue" : "black" }}>{row.tgl_masuk ? new Date(row.tgl_masuk).toLocaleDateString("id-ID") : ""}</td>
                                    <td style={{ color: row.hari === 0 ? "red" : row.hari < 90 ? "#e615d4" : row.hari < 180 && row.hari > 90 ? "blue" : "black" }}>{row.tgl_masuk ? new Date(row.expired_date).toLocaleDateString("id-ID") : ""}</td>
                                    <td style={{ color: row.hari === 0 ? "red" : row.hari < 90 ? "#e615d4" : row.hari < 180 && row.hari > 90 ? "blue" : "black" }}>{row.aging_days}</td>
                                    <td style={{ color: row.hari === 0 ? "red" : row.hari < 90 ? "#e615d4" : row.hari < 180 && row.hari > 90 ? "blue" : "black" }}>{row.shelf_life_days}</td>
                                    <td style={{ color: row.hari === 0 ? "red" : row.hari < 90 ? "#e615d4" : row.hari < 180 && row.hari > 90 ? "blue" : "black" }}>{typeof row.shelf_life_month === "number" ? row.shelf_life_month.toFixed(2) : row.shelf_life_month}</td>
                                    <td style={{ color: row.hari === 0 ? "red" : row.hari < 90 ? "#e615d4" : row.hari < 180 && row.hari > 90 ? "blue" : "black" }}>{row.shelf_life}</td>
                                    {/* <td style={{ color: row.hari === 0 ? "red" : row.hari < 90 ? "#e615d4" : row.hari < 180 && row.hari > 90 ? "blue" : "black" }}>{row.hari}</td> */}
                                    <td style={{ color: row.hari === 0 ? "red" : row.hari < 90 ? "#e615d4" : row.hari < 180 && row.hari > 90 ? "blue" : "black" }}>{row.beginning_colly}</td>
                                    <td style={{ color: row.hari === 0 ? "red" : row.hari < 90 ? "#e615d4" : row.hari < 180 && row.hari > 90 ? "blue" : "black" }}>{row.beginning_kg.toFixed(2)}</td>
                                    <td style={{ color: row.hari === 0 ? "red" : row.hari < 90 ? "#e615d4" : row.hari < 180 && row.hari > 90 ? "blue" : "black" }}>{row.beginning_pallet}</td>
                                    <td style={{ color: row.hari === 0 ? "red" : row.hari < 90 ? "#e615d4" : row.hari < 180 && row.hari > 90 ? "blue" : "black" }}>{row.mutasi_in_colly}</td>
                                    <td style={{ color: row.hari === 0 ? "red" : row.hari < 90 ? "#e615d4" : row.hari < 180 && row.hari > 90 ? "blue" : "black" }}>{row.mutasi_in_kg}</td>
                                    <td style={{ color: row.hari === 0 ? "red" : row.hari < 90 ? "#e615d4" : row.hari < 180 && row.hari > 90 ? "blue" : "black" }}>{row.mutasi_in_pallet}</td>
                                    <td style={{ color: row.hari === 0 ? "red" : row.hari < 90 ? "#e615d4" : row.hari < 180 && row.hari > 90 ? "blue" : "black" }}>{row.mutasi_out_colly}</td>
                                    <td style={{ color: row.hari === 0 ? "red" : row.hari < 90 ? "#e615d4" : row.hari < 180 && row.hari > 90 ? "blue" : "black" }}>{row.mutasi_out_kg}</td>
                                    <td style={{ color: row.hari === 0 ? "red" : row.hari < 90 ? "#e615d4" : row.hari < 180 && row.hari > 90 ? "blue" : "black" }}>{row.mutasi_out_pallet}</td>
                                    <td style={{ color: row.hari === 0 ? "red" : row.hari < 90 ? "#e615d4" : row.hari < 180 && row.hari > 90 ? "blue" : "black" }}>{row.adjustment_colly.toFixed(2)}</td>
                                    <td style={{ color: row.hari === 0 ? "red" : row.hari < 90 ? "#e615d4" : row.hari < 180 && row.hari > 90 ? "blue" : "black" }}>{row.adjustment_kg.toFixed(2)}</td>
                                    <td style={{ color: row.hari === 0 ? "red" : row.hari < 90 ? "#e615d4" : row.hari < 180 && row.hari > 90 ? "blue" : "black" }}>{row.adjustment_pallet.toFixed(2)}</td>
                                    <td style={{ color: row.hari === 0 ? "red" : row.hari < 90 ? "#e615d4" : row.hari < 180 && row.hari > 90 ? "blue" : "black" }}>{row.ending_stock_colly}</td>
                                    <td style={{ color: row.hari === 0 ? "red" : row.hari < 90 ? "#e615d4" : row.hari < 180 && row.hari > 90 ? "blue" : "black" }}>{row.ending_stock_kg.toFixed(2)}</td>
                                    <td style={{ color: row.hari === 0 ? "red" : row.hari < 90 ? "#e615d4" : row.hari < 180 && row.hari > 90 ? "blue" : "black" }}>{row.ending_stock_pallet}</td>
                                    <td style={{ color: row.hari === 0 ? "red" : row.hari < 90 ? "#e615d4" : row.hari < 180 && row.hari > 90 ? "blue" : "black" }}>{row.hasil_sampling}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                    <tfoot style={{ backgroundColor: "#b9d2fa", }}>
                        <tr style={{ fontSize: "11px", fontWeight: "500", textAlign: "center", }}>
                            <th scope="row" style={{ fontSize: "12px", fontWeight: "500", backgroundColor: "#b9d2fa", textAlign: "center", }}>Total</th>
                            <td style={{ backgroundColor: "#b9d2fa" }}></td>
                            <td style={{ backgroundColor: "#b9d2fa" }}></td>
                            <td style={{ backgroundColor: "#b9d2fa" }}></td>
                            <td style={{ backgroundColor: "#b9d2fa" }}></td>
                            <td style={{ backgroundColor: "#b9d2fa" }}></td>
                            <td style={{ backgroundColor: "#b9d2fa" }}></td>
                            <td style={{ backgroundColor: "#b9d2fa" }}></td>
                            <td style={{ backgroundColor: "#b9d2fa" }}></td>
                            <td style={{ backgroundColor: "#b9d2fa" }}></td>
                            <td style={{ backgroundColor: "#b9d2fa" }}>{totalBeginCollyArray.reduce((total, item) => total + item, 0).toFixed(2)}</td>
                            <td style={{ backgroundColor: "#b9d2fa" }}>{totalBeginKgArray.reduce((total, item) => total + item, 0).toFixed(2)}</td>
                            <td style={{ backgroundColor: "#b9d2fa" }}>{totalBeginPalletArray.reduce((total, item) => total + item, 0).toFixed(2)}</td>
                            <td style={{ backgroundColor: "#b9d2fa" }}>{totalMutasiInCollyArray.reduce((total, item) => total + item, 0).toFixed(2)}</td>
                            <td style={{ backgroundColor: "#b9d2fa" }}>{totalMutasiInKgArray.reduce((total, item) => total + item, 0).toFixed(2)}</td>
                            <td style={{ backgroundColor: "#b9d2fa" }}>{totalMutasiInPalletArray.reduce((total, item) => total + item, 0).toFixed(2)}</td>
                            <td style={{ backgroundColor: "#b9d2fa" }}>{totalMutasiOutCollyArray.reduce((total, item) => total + item, 0).toFixed(2)}</td>
                            <td style={{ backgroundColor: "#b9d2fa" }}>{totalMutasiOutKgArray.reduce((total, item) => total + item, 0).toFixed(2)}</td>
                            <td style={{ backgroundColor: "#b9d2fa" }}>{totalMutasiOutPalletArray.reduce((total, item) => total + item, 0).toFixed(2)}</td>
                            <td style={{ backgroundColor: "#b9d2fa" }}>{totalAdjustmentCollyArray.reduce((total, item) => total + item, 0).toFixed(2)}</td>
                            <td style={{ backgroundColor: "#b9d2fa" }}>{totalAdjustmentKgArray.reduce((total, item) => total + item, 0).toFixed(2)}</td>
                            <td style={{ backgroundColor: "#b9d2fa" }}>{totalAdjustmentPalletArray.reduce((total, item) => total + item, 0).toFixed(2)}</td>
                            <td style={{ backgroundColor: "#b9d2fa" }}>{totalEndingStockCollyArray.reduce((total, item) => total + item, 0).toFixed(2)}</td>
                            <td style={{ backgroundColor: "#b9d2fa" }}>{totalEndingStockKgArray.reduce((total, item) => total + item, 0).toFixed(2)}</td>
                            <td style={{ backgroundColor: "#b9d2fa" }}>{totalEndingStockPalletArray.reduce((total, item) => total + item, 0).toFixed(2)}</td>
                            <td style={{ backgroundColor: "#b9d2fa" }}></td>
                        </tr>
                    </tfoot>
                </Table>
            </Sheet >
        </>
    );
}

export default TabelExpStock