import * as React from "react";
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
import ModalTable from "../../molecules/ModalTable";
import { useState } from "react";

const TabelProduksi = (props) => {
  const [namaRow, setNamaRow] = useState("");
  const [judulTabel, setJudulTabel] = useState("");

  const data = props.data;
  const allData = props.allData;
  const query = props.query;

  return (
    <>
      <ModalTable namaTabel={namaRow} data={allData} judulTabel={judulTabel} />
      <Sheet sx={{ height: "fit", maxHeight: "75vh", overflow: "auto", }}>
        <Table
          borderAxis="both-between"
          stripe="odd"
          hoverRow
          size="sm"
          stickyHeader
          stickyFooter
          sx={{
            "--TableCell-paddingY": "2px",
            "--TableCell-paddingX": "0px",
            "--TableCell-height": "23px",
            "--Table-headerUnderlineThickness": "1px",
          }}
          ref={props.reference}>
          <thead>
            <tr style={{ color: "white", fontSize: "12px", fontWeight: "100", textAlign: "center", }}>
              <th style={{ width: 200, backgroundColor: "#8ca2f5", textAlign: "center", color: "white", verticalAlign: "middle" }}>Nama Bahan</th>
              <th style={{ width: 200, backgroundColor: "#8ca2f5", textAlign: "center", color: "white", verticalAlign: "middle" }}>Total PCS</th>
              <th style={{ width: 200, backgroundColor: "#8ca2f5", textAlign: "center", color: "white", verticalAlign: "middle" }}>Total Qty</th>
              <th style={{ width: 200, backgroundColor: "#8ca2f5", textAlign: "center", color: "white", verticalAlign: "middle" }}>Total Rendemen</th>
              <th style={{ width: 200, backgroundColor: "#8ca2f5", textAlign: "center", color: "white", verticalAlign: "middle" }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.filter((item) =>
              item.nama_data.toLowerCase().includes(query)
            ).map((row, index) => {
              return (
                <tr key={index} style={{ fontSize: "11px", fontWeight: "400", textAlign: "center", }}>
                  <td>{row.nama_upper}</td>
                  <td>{row.total_pcs}</td>
                  <td>{row.total_qty}</td>
                  <td>{row.total_rendemen}</td>
                  <td><button onClick={() => { setNamaRow(row.nama_data); setJudulTabel(row.nama_upper); document.getElementById("my_modal_2").showModal(); }}
                    className="btn btn-outline btn-xs font-thin">View</button></td>
                </tr>
              );
            })}
          </tbody>
          <tfoot style={{ backgroundColor: "#b9d2fa", }}>
            <tr style={{ fontSize: "11px", fontWeight: "500", textAlign: "center", }}>
              <th scope="row" style={{ fontSize: "12px", fontWeight: "500", backgroundColor: "#b9d2fa", textAlign: "center", }}>Total</th>
              <td style={{ backgroundColor: "#b9d2fa", }}></td>
              <td style={{ backgroundColor: "#b9d2fa", }}></td>
              <td style={{ backgroundColor: "#b9d2fa", }}></td>
              <td style={{ backgroundColor: "#b9d2fa", }}></td>
            </tr>
          </tfoot>
        </Table>
      </Sheet >
    </>
  );
};

export default TabelProduksi;
