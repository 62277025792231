import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const ProfilePage = () => {
    const [data, setData] = useState();
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // const first_name = formData.data[first_name]
        // const last_name = formData.data[last_name]
        // const email = formData.data[email]

        let bodyContent = `username=${data.username}&function=update&first_name=${formData.first_name}&last_name=${formData.last_name}&email=${formData.email}&function=update`
        console.log(formData);
        try {
            const response = await fetch('http://of.fekusa.com:4001/rpa_sync/backend/user.php', {
                method: 'POST',
                headers: {
                    "Accept": "*/*",
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                body: bodyContent,
            });
            const data = await response.json();
            console.log(data);
        } catch (error) {
            console.error('Error updating profile:', error);
        }
    };

    useEffect(() => {
        setData(JSON.parse(sessionStorage.getItem("data")));
    }, []);

    return (
        <>
            {data &&
                <div className=' items-center justify-center h-screen w-full'>
                    <form onSubmit={handleSubmit} >
                        <label>
                            Username:
                            <input
                                type="text"
                                name="username"
                                value={data.username}
                                className='border'
                                disabled
                            />
                        </label>
                        <label>
                            first name:
                            <input
                                type="text"
                                name="first_name"
                                value={formData.first_name}
                                onChange={handleChange}
                                className='border'
                            />
                        </label>
                        <label>
                            last name:
                            <input
                                type="text"
                                name="last_name"
                                value={formData.last_name}
                                onChange={handleChange}
                                className='border'
                            />
                        </label>
                        <label>
                            email:
                            <input
                                type="text"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className='border'
                            />
                        </label>
                        <button type="submit" className='btn'>Update Profile</button>
                    </form>
                </div >
            }
        </>
    )
}

export default ProfilePage