import React, { useEffect, useState } from "react";
import { FaTachometerAlt, FaRegCalendarAlt, FaBars, FaUserFriends } from "react-icons/fa";
import { FaBoxesPacking } from "react-icons/fa6";
import { TbTruckLoading } from "react-icons/tb";
import { TbMeat } from "react-icons/tb";
import logo from "../../assets/logo.jpg";
import { Link, NavLink } from "react-router-dom";
import SidebarMenu from "../../molecules/SidebarMenu";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [data, setData] = useState();

  useEffect(() => {
    setData(JSON.parse(sessionStorage.getItem("data")));
  }, []);
  return (
    <>
      {data && (
        <><div className={`bg-white px-[15px] h-screen border-r-2 border-[#8ca2f5] ${isOpen ? 'w-[200px]' : 'w-[62px]'} duration-300 rounded-md shadow-sm relative`}>
          <div className={`p-2 ml-auto absolute -right-8 top-5 bg-white cursor-pointer hover:text-[#0950C3]`} onClick={() => setIsOpen(!isOpen)}><FaBars /></div>
          <NavLink to={"/"}>
            <div className="pt-4 flex items-center justify-center">
              <img src={logo} height={70} width={70} alt="logo" />
            </div>
          </NavLink>
          <div className="pt-[15px] border-b-[1px] border-[#2350B8]/[0.3]">
            <SidebarMenu path="/" text="Dashboard" icon={FaTachometerAlt} isOpen={isOpen} />
          </div>
          <div className="border-b-[1px] border-[#2350B8]/[0.3]">
            <SidebarMenu
              path="/stock"
              text="Stock"
              icon={TbMeat}
              isOpen={isOpen}
            />
            <SidebarMenu
              path="/expired-stock"
              text="Expired Stock"
              icon={FaRegCalendarAlt}
              isOpen={isOpen}
            />
            <SidebarMenu
              path="/penerimaan"
              text="Raw Materials"
              icon={FaBoxesPacking}
              isOpen={isOpen}
            />
            <SidebarMenu
              path="/produksi"
              text="Produksi"
              icon={TbTruckLoading}
              isOpen={isOpen}
            />
          </div>
          {data.company === "ADMIN" &&
            (<SidebarMenu
              path="/users"
              text="Users"
              icon={FaUserFriends}
              isOpen={isOpen}
            />)
          }
        </div>
        </>
      )}

    </>
  );
};

export default Sidebar;
