import * as React from "react";
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";

const TabelPenerimaan = (props) => {
  const data = props.data;
  const query = props.query;
  return (
    <>
      <Sheet sx={{ height: "fit", maxHeight: "75vh", overflow: "auto", }}>
        <Table
          borderAxis="both-between"
          stripe="odd"
          hoverRow
          size="sm"
          stickyHeader
          stickyFooter
          sx={{
            "--TableCell-paddingY": "2px",
            "--TableCell-paddingX": "0px",
            "--TableCell-height": "23px",
            "--Table-headerUnderlineThickness": "1px",
          }}
          ref={props.reference}>
          <thead>
            <tr style={{ color: "white", fontSize: "12px", fontWeight: "100", textAlign: "center", }}>
              <th style={{ width: 60, backgroundColor: "#8ca2f5", textAlign: "center", color: "white", verticalAlign: "middle" }} rowSpan={2}>Tanggal</th>
              <th style={{ width: 40, backgroundColor: "#8ca2f5", textAlign: "center", color: "white", verticalAlign: "middle" }} rowSpan={2}>Rit</th>
              <th style={{ width: 240, backgroundColor: "#8ca2f5", textAlign: "center", color: "white", verticalAlign: "middle" }} colSpan={4}>Suplier</th>
              <th style={{ width: 120, backgroundColor: "#8ca2f5", textAlign: "center", color: "white", verticalAlign: "middle" }} colSpan={3}>Realisasi (SJ)</th>
              <th style={{ width: 120, backgroundColor: "#8ca2f5", textAlign: "center", color: "white", verticalAlign: "middle" }} colSpan={3}>Diterima RPA</th>
              <th style={{ width: 100, backgroundColor: "#8ca2f5", textAlign: "center", color: "white", verticalAlign: "middle" }} colSpan={2}>Mati</th>
              <th style={{ width: 100, backgroundColor: "#8ca2f5", textAlign: "center", color: "white", verticalAlign: "middle" }} colSpan={2}>Susut</th>
              <th style={{ width: 60, backgroundColor: "#8ca2f5", textAlign: "center", color: "white", verticalAlign: "middle" }}>Selisih</th>
            </tr>
            <tr>
              <th style={{ textAlign: "center", backgroundColor: "#8ca2f5", color: "white" }}>Status</th>
              <th style={{ textAlign: "center", backgroundColor: "#8ca2f5", color: "white" }} colSpan={3}>Nama</th>
              {/* <th style={{ textAlign: "center", backgroundColor: "#8ca2f5", color: "white" }}>Alamat</th> */}
              <th style={{ textAlign: "center", backgroundColor: "#8ca2f5", color: "white" }}>Ekor</th>
              <th style={{ textAlign: "center", backgroundColor: "#8ca2f5", color: "white" }}>KG</th>
              <th style={{ textAlign: "center", backgroundColor: "#8ca2f5", color: "white" }}>ABW</th>
              <th style={{ textAlign: "center", backgroundColor: "#8ca2f5", color: "white" }}>Ekor</th>
              <th style={{ textAlign: "center", backgroundColor: "#8ca2f5", color: "white" }}>KG</th>
              <th style={{ textAlign: "center", backgroundColor: "#8ca2f5", color: "white" }}>ABW</th>
              <th style={{ textAlign: "center", backgroundColor: "#8ca2f5", color: "white" }}>Ekor</th>
              <th style={{ textAlign: "center", backgroundColor: "#8ca2f5", color: "white" }}>KG</th>
              <th style={{ textAlign: "center", backgroundColor: "#8ca2f5", color: "white" }}>Ekor</th>
              <th style={{ textAlign: "center", backgroundColor: "#8ca2f5", color: "white" }}>%</th>
              <th style={{ textAlign: "center", backgroundColor: "#8ca2f5", color: "white" }}>Ekor</th>
            </tr>
          </thead>
          <tbody>
            {data.filter((item) =>
              item.vendor_name.toLowerCase().includes(query)
            ).map((row, index) => {
              return (
                <tr key={index} style={{ fontSize: "11px", fontWeight: "400", textAlign: "center", }}>
                  <td>{row.ymd}</td>
                  <td>{row.rit}</td>
                  <td>{row.status}</td>
                  {/* <td>{row.vendor_address}</td> */}
                  <td style={{ textAlign: "left", paddingLeft: "10px" }} colSpan={3}>{row.vendor_name}</td>
                  <td>{row.sj_ea ? row.status : "-"}</td>
                  <td>{row.sj_kg}</td>
                  <td>{row.sj_abw}</td>
                  <td>{row.lb_ea}</td>
                  <td>{row.lb_kg}</td>
                  <td>{row.lb_abw}</td>
                  <td>{row.death_ea}</td>
                  <td>{row.death_kg}</td>
                  <td>{row.lb_abw}</td>
                  <td>{row.lb_abw}</td>
                  <td>{row.lb_abw}</td>
                </tr>
              );
            })}
          </tbody>
          <tfoot style={{ backgroundColor: "#b9d2fa", }}>
            <tr style={{ fontSize: "11px", fontWeight: "500", textAlign: "center", }}>
              <th scope="row" style={{ fontSize: "12px", fontWeight: "500", backgroundColor: "#b9d2fa", textAlign: "center", }}>Total</th>
              <td style={{ backgroundColor: "#b9d2fa", }}></td>
              <td style={{ backgroundColor: "#b9d2fa", }}></td>
              <td style={{ backgroundColor: "#b9d2fa", }} colSpan={3}></td>
              <td style={{ backgroundColor: "#b9d2fa", }}></td>
              <td style={{ backgroundColor: "#b9d2fa", }}></td>
              <td style={{ backgroundColor: "#b9d2fa", }}></td>
              <td style={{ backgroundColor: "#b9d2fa", }}></td>
              <td style={{ backgroundColor: "#b9d2fa", }}></td>
              <td style={{ backgroundColor: "#b9d2fa", }}></td>
              <td style={{ backgroundColor: "#b9d2fa", }}></td>
              <td style={{ backgroundColor: "#b9d2fa", }}></td>
              <td style={{ backgroundColor: "#b9d2fa", }}></td>
              <td style={{ backgroundColor: "#b9d2fa", }}></td>
              <td style={{ backgroundColor: "#b9d2fa", }}></td>
            </tr>
          </tfoot>
        </Table>
      </Sheet >
    </>
  );
};

export default TabelPenerimaan;
