import React, { useState } from "react";

const TextInput = (props) => {
    // const [query, setQuery] = useState("")
    const isLoading = props.isLoading;
    const getFromChild = props.getFromChild;

    const handleInputChange = (e) => {
        const newValue = e.target.value.toLowerCase();
        // setQuery(newValue);
        getFromChild(newValue);
    };

    return (
        <div className="flex">
            <div className="">
                <input
                    aria-label="Search"
                    disabled={isLoading ? true : false}
                    type="text"
                    className="h-8 outline-none px-3 w-[250px] rounded-md placeholder:text-xs text-xs leading-[20px] border focus:border-[#2350B8] hover:border-[#adadaf]"
                    placeholder="Cari Barang..."
                    onChange={handleInputChange}
                />
            </div>
        </div>
    );
};

export default TextInput;
