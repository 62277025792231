import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import App from "../App";
import Main from "../components/Main";
import StockPage from "../pages/Stock/StockPage";
import ExpiredStockPage from "../pages/ExpiredStock/ExpiredStockPage";
import ProduksiPage from "../pages/Produksi/ProduksiPage";
import PenerimaanPage from "../pages/Penerimaan/PenerimaanPage";
import LoginPage from "../pages/Login/LoginPage";
import SignUp from "../pages/SignUp/SignUp";
import ProfilePage from "../pages/Profile/ProfilePage";
import UsersPage from "../pages/Users/UsersPage";

const Router = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/" element={<App />}>
            <Route index element={<Main />} />
            <Route path="/produksi" element={<ProduksiPage />} />
            <Route path="/penerimaan" element={<PenerimaanPage />} />
            <Route path="/stock" element={<StockPage />} />
            <Route path="/expired-stock" element={<ExpiredStockPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/users" element={<UsersPage />} />
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default Router;
