import React, { useState } from 'react'

const DateInput = (props) => {
    const [date, setDate] = useState(props.tgl)
    const isLoading = props.isLoading;
    const getFromChild = props.getFromChild;

    const handleInputChange = (e) => {
        const newValue = e.target.value;
        setDate(newValue);
        getFromChild(newValue);
        console.log("new value", newValue);
    };

    return (
        <div>
            <input
                aria-label="date"
                disabled={isLoading ? true : false}
                type="date"
                className="h-8 outline-none pl-2 mr-1 w-28 rounded-l-md placeholder:text-xs text-xs leading-[20px]"
                value={date}
                onChange={handleInputChange}
            />
        </div>
    )
}

export default DateInput