import React from 'react'
import { Link } from 'react-router-dom'

const SignUp = () => {
    return (
        <div>SignUp <Link to={"/login"} style={{ textDecoration: 'none' }}><p>Kembali</p></Link></div>

    )
}

export default SignUp