import React, { useEffect, useRef, useState } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { format } from "date-fns";
import LoaderTable from "../../molecules/LoaderTable";
import TabelExpStock from "../../components/Table/TabelExpStock";
import TextInput from "../../molecules/TextInput";
import { IoFilterSharp } from "react-icons/io5";
import { IoMdSettings } from "react-icons/io";
import { SiMicrosoftexcel } from "react-icons/si";
import { useDownloadExcel } from 'react-export-table-to-excel';


const ExpiredStockPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [allData, setAllData] = useState([]);
  const [plant, setPlant] = useState("Beji");
  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState("");
  const [filterValue, setFilterValue] = useState("All");
  const tableRef = useRef(null);

  function getFromChildText(data) {
    setQuery(data)
  }

  const apiUrl = process.env.REACT_APP_API_URL_EXPIRED_STOCK

  useEffect(() => {
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setAllData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `Expired Stock ${plant}`,
    sheet: `${plant}`
  })

  return (
    <>
      <div className="mt-[10px] w-full max-w-[98%] mx-auto h-fit font-light">
        <div className="basis-[70%] border bg-white shadow-md rounded-[4px]">
          <div className="container-fluid p-2">
            <div className="table-responsive">
              <div className="flex">
                <div className="flex">
                  <div
                    className={` ${isLoading ? 'hidden' : 'px-4 py-2 rounded-t-md cursor-pointer hover:bg-[#2350B8] hover:text-white'} ${plant === "Beji"
                      ? "bg-[#2350B8] text-white"
                      : "border border-b-0 border-[#2350B8] text-[#2350B8]"
                      }`}
                    onClick={() => setPlant("Beji")}
                  >
                    Beji
                  </div>
                  <div
                    className={` ${isLoading ? 'hidden' : 'px-4 py-2 rounded-t-md cursor-pointer hover:bg-[#2350B8] hover:text-white'} ${plant === "Bosco"
                      ? "bg-[#2350B8] text-white"
                      : "border border-b-0 border-[#2350B8] text-[#2350B8]"
                      }`}
                    onClick={() => setPlant("Bosco")}
                  >
                    Bosco
                  </div>
                  <div
                    className={` ${isLoading ? 'hidden' : 'px-4 py-2 rounded-t-md cursor-pointer hover:bg-[#2350B8] hover:text-white'} ${plant === "Tirta"
                      ? "bg-[#2350B8] text-white"
                      : "border border-b-0 border-[#2350B8] text-[#2350B8]"
                      }`}
                    onClick={() => setPlant("Tirta")}
                  >
                    Tirtamas
                  </div>
                  <div
                    className={` ${isLoading ? 'hidden' : 'px-4 py-2 rounded-t-md cursor-pointer hover:bg-[#2350B8] hover:text-white'} ${plant === "Kiat"
                      ? "bg-[#2350B8] text-white"
                      : "border border-b-0 border-[#2350B8] text-[#2350B8]"
                      }`}
                    onClick={() => setPlant("Kiat")}
                  >
                    Kiat Ananda
                  </div>
                </div>
                <div className="ml-auto flex items-center">
                  <div className="flex items-center rounded-[5px] mr-1">
                    <TextInput getFromChild={getFromChildText} isLoading={isLoading} />
                  </div>
                  <div className="flex items-center">
                    <div className="flex">
                      <div className="flex items-center dropdown dropdown-hover dropdown-end cursor-default" tabIndex={0}>
                        <div>
                          <div className="h-8 outline-none px-2 rounded-md border hover:border-[#adadaf] items-center flex">
                            <IoFilterSharp />
                          </div>
                          <div tabIndex={0} className="bg-white border w-[150px] absolute z-20 right-0 pt-[15px] pl-[15px] rounded-md border-[#c3cee8] dropdown-content p-3">
                            <div className={`cursor-pointer hover:text-white hover:bg-blue-500 w-full p-1 rounded-md flex items-center gap-1 duration-100 text-gray-800 mb-1 
                            ${filterValue === "All" ? "bg-blue-500 text-white" : ""}`}
                              onClick={() => { setFilter(""); setFilterValue("All") }}>
                              <IoMdSettings />
                              <p>{`All`}</p>
                            </div>
                            <div className={`cursor-pointer hover:text-white hover:bg-blue-500 w-full p-1 rounded-md flex items-center gap-1 duration-100 text-gray-800 mb-1 
                            ${filterValue === "3" ? "bg-blue-500 text-white" : ""}`}
                              onClick={() => { setFilter(90); setFilterValue("3") }}>
                              <IoMdSettings />
                              <p>{`<3 Bulan`}</p>
                            </div>
                            <div className={`cursor-pointer hover:text-white hover:bg-blue-500 w-full p-1 rounded-md flex items-center gap-1 duration-100 text-gray-800 mb-1 
                            ${filterValue === "6" ? "bg-blue-500 text-white" : ""}`}
                              onClick={() => { setFilter(180); setFilterValue("6") }}>
                              <IoMdSettings />
                              <p>{`<6 Bulan`}</p>
                            </div>
                            <div className={`cursor-pointer hover:text-white hover:bg-blue-500 w-full p-1 rounded-md flex items-center gap-1 duration-100 text-gray-800 mb-1 
                            ${filterValue === "Expired" ? "bg-blue-500 text-white" : ""}`}
                              onClick={() => { setFilter("expired"); setFilterValue("Expired") }}>
                              <IoMdSettings />
                              <p>{`Expired`}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`h-8 outline-none px-2 rounded-md border items-center flex ml-1 ${isLoading ?
                    "cursor-not-allowed bg-slate-50" :
                    "hover:bg-green-600 hover:text-white border-green-600 text-green-600 cursor-pointer"} `}
                    onClick={!isLoading ? onDownload : undefined}><SiMicrosoftexcel />
                  </div>
                </div>
              </div>
              {isLoading ? (
                <div>
                  <LoaderTable />
                </div>
              ) : <>
                {plant === "Beji" && (
                  <TabelExpStock data={allData.rpa_beji_stck} query={query} filter={filter} reference={tableRef} />
                )}
                {plant === "Bosco" && (
                  <TabelExpStock data={allData.bsc_umr_stck} query={query} filter={filter} reference={tableRef} />
                )}
                {plant === "Tirta" && (
                  <TabelExpStock data={allData.tcl_umr_stck} query={query} filter={filter} reference={tableRef} />
                )}
                {plant === "Kiat" && (
                  <TabelExpStock data={allData.kacs_umr_stck} query={query} filter={filter} reference={tableRef} />
                )}
              </>}
            </div>
          </div >
        </div >
      </div >
    </>
  );
};

export default ExpiredStockPage;
